import { Pipe, PipeTransform } from '@angular/core';
import { ChecklistSection, ChecklistTaskModelType } from '@fieldos/models';
import { isChecklistTask } from './checklist-viewer.utils';

@Pipe({
  name: 'isChecklistSection',
  standalone: true,
})
export class IsChecklistSectionPipe implements PipeTransform {
  transform(value: ChecklistTaskModelType): ChecklistSection | null {
    return isChecklistTask(value) ? null : value;
  }
}
