import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BpDragDropModule } from '@browsepedia/drag-and-drop';
import { Field } from '@fieldos/models';
import { TranslocoModule } from '@ngneat/transloco';
import { FIELDS_DESIGNER_STORE } from '../../fields-designer.store';
import { FieldsDesignerFieldComponent } from '../fields-designer-field/fields-designer-field.component';

@Component({
  selector: 'app-fields-designer-container-field',
  templateUrl: './fields-designer-container-field.component.html',
  standalone: true,
  imports: [
    TranslocoModule,
    BpDragDropModule,
    FieldsDesignerFieldComponent,
    MatIconModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class FieldsDesignerContainerFieldComponent {
  public readonly field = input.required<Field>();
  public readonly isFixed = input<boolean>(false);

  protected store = inject(FIELDS_DESIGNER_STORE);

  protected readonly fields = computed(() => {
    const field = this.field();

    if (this.isFixed()) {
      return this.store
        .fixedFields()
        .filter((e) => e.properties?.containerId === field.id);
    }

    return this.store
      .fields()
      .filter((e) => e.properties?.containerId === field.id);
  });

  protected onFieldClicked(): void {
    if (this.field().properties?.arePropertiesReadonly) {
      return;
    }

    this.store.setSelectedFieldId(this.field().id);
  }
}
