import { Component, input, output } from '@angular/core';
import { Checklist, ChecklistTask } from '@fieldos/models';
import { ChecklistSectionViewerComponent } from './checklist-section-viewer/checklist-section-viewer.component';
import { ChecklistTaskViewerComponent } from './checklist-task-viewer/checklist-task-viewer.component';
import { ChecklistTaskValueChangeEvent } from './checklist-viewer.models';
import { IsChecklistSectionPipe } from './is-checklist-section.pipe';
import { IsChecklistTaskPipe } from './is-checklist-task.pipe';

@Component({
  selector: 'app-checklist-viewer',
  templateUrl: './checklist-viewer.component.html',
  standalone: true,
  imports: [
    ChecklistTaskViewerComponent,
    IsChecklistTaskPipe,
    IsChecklistSectionPipe,
    ChecklistSectionViewerComponent,
  ],
})
export class ChecklistViewerComponent {
  public readonly checklist = input.required<Checklist>();
  public readonly taskValues = input<Record<string, unknown>>({});

  public readonly taskValueChange =
    output<ChecklistTaskValueChangeEvent<unknown>>();

  protected onTaskValueChange(value: unknown, task: ChecklistTask): void {
    this.taskValueChange.emit({ value, task });
  }
}
