import { Component, computed, input, output, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { FileUploaderComponent } from '@fieldos/components';
import { ChecklistTask } from '@fieldos/models';
import { TranslatableNamePipe } from '@fuse/pipes';
import { TranslocoModule } from '@ngneat/transloco';
import { ChecklistYesNoTaskViewerComponent } from './checklist-yes-no-task-viewer/checklist-yes-no-task-viewer.component';

@Component({
  selector: 'app-checklist-task-viewer',
  templateUrl: './checklist-task-viewer.component.html',
  standalone: true,
  imports: [
    MatButtonModule,
    TranslocoModule,
    ChecklistYesNoTaskViewerComponent,
    MatDividerModule,
    FileUploaderComponent,
    TranslatableNamePipe,
  ],
})
export class ChecklistTaskViewerComponent {
  public readonly task = input.required<ChecklistTask<any, any>>();

  public readonly value = input<any>();

  public readonly valueChange = output<unknown>();

  protected readonly showMoreInfo = signal(false);

  protected readonly canShowMoreInfo = computed(
    () => this.task().notes || this.task().link || this.task().files.length
  );
}
