import { Component, input } from '@angular/core';
import { ChecklistSection } from '@fieldos/models';
import { TranslatableNamePipe } from '@fuse/pipes';
import { ChecklistTaskViewerComponent } from '../checklist-task-viewer/checklist-task-viewer.component';

@Component({
  selector: 'app-checklist-section-viewer',
  templateUrl: './checklist-section-viewer.component.html',
  standalone: true,
  imports: [ChecklistTaskViewerComponent, TranslatableNamePipe],
})
export class ChecklistSectionViewerComponent {
  public readonly section = input.required<ChecklistSection>();

  public readonly valueChange = input<unknown>();
}
