import { TranslatableDomainEntityBase } from '@fieldos/models';
import { ChecklistSection, ChecklistTask } from './checklist-task';

export type ChecklistTaskModelType<T = unknown, K = unknown> =
  | ChecklistTask<T, K>
  | ChecklistSection;

export interface Checklist extends TranslatableDomainEntityBase {
  tasks?: ChecklistTaskModelType[];
}
