import { Pipe, PipeTransform } from '@angular/core';
import { ChecklistTask, ChecklistTaskModelType } from '@fieldos/models';
import { isChecklistTask } from './checklist-viewer.utils';

@Pipe({
  name: 'isChecklistTask',
  standalone: true,
})
export class IsChecklistTaskPipe implements PipeTransform {
  transform(value: ChecklistTaskModelType): ChecklistTask | null {
    return isChecklistTask(value) ? value : null;
  }
}
