import { inject, Injectable } from '@angular/core';
import { HttpService } from '@fieldos/core';
import { ChecklistTaskType } from '@fieldos/models';
import { WorkOrderChecklistValue } from './work-order-checklists.models';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderChecklistsFacade {
  private readonly _http = inject(HttpService);

  async fetchChecklists(
    workOrderId: number
  ): Promise<WorkOrderChecklistValue[]> {
    return this._http.get(`/workorders/${workOrderId}/checklists`);
  }

  async saveChecklistTaskValue<T>(
    workOrderId: number,
    checklistId: number,
    taskId: string,
    taskType: ChecklistTaskType,
    value: T
  ): Promise<void> {
    return this._http.post(`/workorders/checklists/tasks`, {
      woId: workOrderId,
      checklistId,
      taskId,
      type: taskType,
      value,
    });
  }
}
