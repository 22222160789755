import {
  ChecklistTask,
  ChecklistTaskConditionActionTask,
} from '../checklist-task';

export enum ChecklistYesNoNaTaskValue {
  No = 0,
  Yes = 1,
  NA = -1,
}

export type ChecklistYesNoNATaskConditionals = Record<
  ChecklistYesNoNaTaskValue,
  ChecklistTaskConditionActionTask | null
>;

export type ChecklistYesNoNaTask = ChecklistTask<
  {
    option: ChecklistYesNoNaTaskValue;
  },
  ChecklistYesNoNATaskConditionals
>;
