@for (task of checklist().tasks; track task.id) {
  @if (task | isChecklistTask; as checklistTask) {
    <app-checklist-task-viewer
      [task]="checklistTask"
      [value]="taskValues()[task.id]"
      (valueChange)="onTaskValueChange($event, checklistTask)" />
  }

  @if (task | isChecklistSection; as checklistSection) {
    <app-checklist-section-viewer [section]="checklistSection" />
  }
}
