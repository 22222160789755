import { computed, effect, signal, untracked } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { DomainEntityBase } from '@fieldos/models';
import { filterEmpty } from '@fieldos/utils';
import { IFilterAngularComp } from 'ag-grid-angular';
import {
  AgPromise,
  IDoesFilterPassParams,
  IFilterParams,
} from 'ag-grid-community';

export interface SetFilterParams
  extends IFilterParams<Record<string, any>, unknown> {
  defaultExcludedValues?: number[];
  searchProperty?: string;
}

export class SetFilterBase<T extends SetFilterParams = SetFilterParams>
  implements IFilterAngularComp
{
  constructor() {
    effect(
      () => {
        this.updateSelectionOnFloatingFilterChange();
      },
      { allowSignalWrites: true }
    );

    effect(
      () => {
        this.selection();
        const params = this.params();
        this.options();

        if (params) {
          params.filterChangedCallback();
        }
      },
      { allowSignalWrites: true }
    );
  }

  protected readonly searchCtrl = new FormControl('', {
    nonNullable: true,
  });

  protected readonly selection = signal<string[] | null>(null);

  protected readonly isSelectAllIntermediate = computed(() => {
    const selection = this.selection();

    if (!selection) {
      return false;
    }

    return selection.length !== 0 && selection.length < this.options().length;
  });

  protected readonly isSelectAllChecked = computed(
    () =>
      this.selection()?.length === this.options().length || !this.selection()
  );

  protected readonly filteredOptions = computed(() => {
    const options = this.options();
    const filter = this._filterChange();

    return options.filter((option) =>
      option.name.toLowerCase().includes((filter || '').toLowerCase())
    );
  });

  protected readonly params = signal<T | null>(null);
  protected readonly floatingFilterValue = signal<string | null | undefined>(
    null
  );

  protected readonly options = signal<DomainEntityBase[]>([]);

  private readonly _filterChange = toSignal(
    this.searchCtrl.valueChanges.pipe(filterEmpty())
  );

  onFloatingFilterChanged(value: string): void {
    this.floatingFilterValue.set(value);
  }

  agInit(params: T): void {
    this.params.set(params);
  }

  isFilterActive(): boolean {
    return true;
  }

  doesFilterPass(
    params: IDoesFilterPassParams<Record<string, unknown>>
  ): boolean {
    const field = this.params()?.colDef.field;

    if (field) {
      let columnValue = params.data[field];

      if (!this.selection()) {
        return true;
      }

      if (!columnValue) {
        return false;
      }

      if ((columnValue as any)['locationId']) {
        columnValue = (columnValue as any)['locationId'];
      }

      return !!this.selection()?.includes(`${columnValue}`);
    }

    return false;
  }

  getModel(): string | string[] | null {
    return this.floatingFilterValue() || this.selection();
  }

  setModel(model: string[] | null): void | AgPromise<void> {
    this.selection.set(model);
    this.params()?.filterChangedCallback();
  }

  getModelAsString?(model: string[]): string {
    return this.filteredOptions()
      .filter((e) => model.includes(`${e.id}`))
      .map((e) => e.name)
      .join(', ');
  }

  clearModel?(): void {
    this.selection.set(null);
    this.floatingFilterValue.set('');
    this.params()?.filterChangedCallback();
  }

  protected updateSelectionOnFloatingFilterChange(): void {
    const value = this.floatingFilterValue();
    const params = untracked(this.params);

    if (value === undefined) {
      return;
    }

    if (value === null || value === '') {
      this.selection.set(null);
      params?.filterChangedCallback();
      return;
    }

    const searchProperty = params?.searchProperty;

    if (searchProperty) {
      const passedFilterValues = this.options().filter((e) =>
        (e as any)[searchProperty].toLowerCase().includes(value.toLowerCase())
      );
      this.selection.set(passedFilterValues.map((e) => `${e.id}`));
      params?.filterChangedCallback();

      return;
    }

    const passedFilterValues = this.options().filter(
      (e) =>
        e.name.toLowerCase().includes(value.toLowerCase()) ||
        e.id.toString() === value
    );

    this.selection.set(passedFilterValues.map((e) => `${e.id}`));
    params?.filterChangedCallback();
  }

  protected onSelectAllChange(): void {
    this.floatingFilterValue.set(undefined);

    if (this.isSelectAllIntermediate() || this.selection()?.length === 0) {
      this.selection.set(null);
    } else {
      this.selection.set([]);
    }

    this._onFilterChange();
  }

  protected onOptionSelected(option: DomainEntityBase): void {
    this.floatingFilterValue.set(undefined);
    const value = this.selection();

    if (value === null) {
      const newSelection = this.options()
        .map((e) => `${e.id}`)
        .filter((e) => e !== `${option.id}`);

      this.selection.set(newSelection);
      this._onFilterChange();
      return;
    }

    if (value.includes(`${option.id}`)) {
      this.selection.set(value.filter((e) => e !== `${option.id}`));
      this._onFilterChange();
    } else {
      this.selection.set([...(this.selection() as []), `${option.id}`]);
    }
    this._onFilterChange();
  }

  private _onFilterChange(): void {
    this.setModel(this.selection());
    this.params()?.filterChangedCallback();
  }
}
