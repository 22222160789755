import { Component } from '@angular/core';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ElementRef,
  inject,
  signal,
  ViewChild,
} from '@angular/core';
import { TranslatableNameDescriptionModel } from '@fieldos/models';
import { TranslatableNamePipe } from '@fuse/pipes';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

interface ITranslatableCellRendererParams
  extends ICellRendererParams<
    unknown,
    TranslatableNameDescriptionModel,
    unknown
  > {
  styles?: Record<string, string>;
}

@Component({
  selector: 'app-translatable-label-cell-renderer',
  templateUrl: './translatable-label-cell-renderer.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
    `,
  ],
  imports: [TranslatableNamePipe],
})
export class TranslatableLabelCellRendererComponent
  implements ICellRendererAngularComp, AfterViewInit
{
  @ViewChild('cell')
  private readonly _cell!: ElementRef<HTMLDivElement>;

  protected readonly styles = signal<Record<string, string>>({});

  protected get tooltipDisabled(): boolean {
    if (!this._cell) {
      return false;
    }

    return (
      this._cell.nativeElement.scrollWidth <=
      this._cell.nativeElement.clientWidth
    );
  }

  protected readonly value = signal<TranslatableNameDescriptionModel>({});

  private readonly _detector = inject(ChangeDetectorRef);

  agInit(params: ITranslatableCellRendererParams): void {
    this.value.set(params.value || {});

    if (params.styles) {
      this.styles.set(params.styles || {});
    }

    this._detector.detectChanges();
  }

  refresh(params: ITranslatableCellRendererParams): boolean {
    this.value.set(params.value || {});

    return true;
  }

  ngAfterViewInit(): void {
    const observer = new ResizeObserver(() => this._detector.detectChanges());
    observer.observe(this._cell.nativeElement);
  }
}
