import { Pipe, PipeTransform, Signal, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'translatableName',
  standalone: true,
})
export class TranslatableNamePipe implements PipeTransform {
  private readonly _language = toSignal(
    inject(TranslocoService).langChanges$
  ) as Signal<string>;

  transform(value: Record<string, { name: string }>): string {
    const currentLanguageValue = value[this._language()];

    if (currentLanguageValue) {
      return currentLanguageValue.name;
    }

    if (!value[Object.keys(value)[0]]) {
      return '';
    }

    return value[Object.keys(value)[0]].name || '';
  }
}
