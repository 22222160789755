<div class="flex flex-col p-4 gap-4">
  <h6 class="text-lg font-bold">
    {{ task().translatableProperties | translatableName }}
  </h6>

  @switch (task().type) {
    @case ('yesnona') {
      <app-checklist-yes-no-task-viewer
        [task]="task()"
        [value]="value()"
        (valueChange)="valueChange.emit($event)" />
    }
  }

  <mat-divider />

  @if (!showMoreInfo() && canShowMoreInfo()) {
    <button mat-stroked-button (click)="showMoreInfo.set(true)">
      {{ 'checklists.see_more_info' | transloco }}
    </button>
  }

  @if (showMoreInfo()) {
    <div class="flex flex-col gap-4 overflow-auto">
      @if (task().notes) {
        <div class="prose" [innerHTML]="task().notes"></div>
      }

      @if (task().link) {
        <button mat-button color="primary">
          {{ 'checklists.link_label' | transloco }}
        </button>
      }

      @if (task().files.length) {
        <app-file-uploader [disabled]="true" [files]="task().files" />
      }
    </div>

    @if (showMoreInfo()) {
      <button mat-stroked-button (click)="showMoreInfo.set(false)">
        {{ 'common.close' | transloco }}
      </button>
    }
  }
</div>
