import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  viewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {
  ChecklistTaskConditionActionTask,
  ChecklistYesNoNaTask,
} from '@fieldos/models';
import { TranslocoModule } from '@ngneat/transloco';
import { ChecklistTaskViewerBaseComponent } from '../checklist-task-viewer-base.component';

@Component({
  selector: 'app-checklist-yes-no-task-viewer',
  templateUrl: './checklist-yes-no-task-viewer.component.html',
  standalone: true,
  imports: [MatButtonModule, TranslocoModule, MatButtonToggleModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistYesNoTaskViewerComponent extends ChecklistTaskViewerBaseComponent<ChecklistYesNoNaTask> {
  protected readonly fileInput =
    viewChild<ElementRef<HTMLInputElement>>('fileInput');

  onYesClicked(): void {
    const action = this.task().conditionals?.[1];

    if (action) {
      this._executeAction(action);
    }
  }

  protected onFileAdded(file: File): void {
    // this.valueChange.emit(file);
  }

  private _executeAction(actionType: ChecklistTaskConditionActionTask): void {
    switch (actionType) {
      case 'upload':
        this.fileInput()?.nativeElement.click();
    }
  }
}
