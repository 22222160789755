<div class="flex gap-2">
  <mat-button-toggle-group
    class="flex-grow mat-primary"
    [value]="value()?.option"
    (change)="onValueChange({ option: $event.value })">
    <mat-button-toggle class="flex-grow" [value]="1">
      {{ 'common.yes' | transloco }}
    </mat-button-toggle>
    <mat-button-toggle class="flex-grow" [value]="0">
      {{ 'common.no' | transloco }}
    </mat-button-toggle>
    <mat-button-toggle class="flex-grow" [value]="-1">
      {{ 'common.not_available' | transloco }}
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>

<input
  #fileInput
  hidden
  (change)="onFileAdded($any($event.target).files[0])"
  accept="*"
  type="file" />
