import { inject } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { ChecklistsDataService } from '@fieldos/data-services';
import { Checklist } from '@fieldos/models';
import { AuthStore } from '@fieldos/store/index';
import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { setAllEntities, withEntities } from '@ngrx/signals/entities';
import { distinctUntilChanged, filter, tap } from 'rxjs';

interface ChecklistsState {
  loading: boolean;
}

export const ChecklistStore = signalStore(
  { providedIn: 'root' },
  withState<ChecklistsState>({
    loading: false,
  }),
  withEntities<Checklist>(),
  withMethods((store, service = inject(ChecklistsDataService)) => ({
    async fetchAll() {
      patchState(store, { loading: true });
      try {
        const checklists = await service.fetchAll();
        patchState(store, setAllEntities(checklists.reverse()));
      } catch (err) {
        console.error('Error fetching work order dpus', err);
      } finally {
        patchState(store, { loading: false });
      }
    },
  })),
  withHooks({
    onInit(store, authStore = inject(AuthStore)) {
      toObservable(authStore.isAuthenticated)
        .pipe(
          distinctUntilChanged(),
          filter((isAuthenticated) => isAuthenticated === true),
          tap(() => {
            store.fetchAll();
          }),
          takeUntilDestroyed()
        )
        .subscribe();
    },
  })
);
