import {
  ChecklistTask,
  ChecklistTaskConditionActionTask,
} from '../checklist-task';

export type ChecklistCheckboxTaskConditionals = {
  0: ChecklistTaskConditionActionTask | null;
  1: ChecklistTaskConditionActionTask | null;
};

export type ChecklistCheckboxTask = ChecklistTask<
  boolean,
  ChecklistCheckboxTaskConditionals
>;
