<app-multiple-select
  [formControl]="form"
  [highlightedOption]="selectedChecklistId()"
  [options]="options()"
  (highlightOption)="onChecklistSelected($event)"
  useHighlight />

@if (selectedChecklist(); as checklist) {
  <app-checklist-viewer
    [checklist]="checklist"
    [taskValues]="taskValueMap()"
    (taskValueChange)="onTaskChange($event)" />
}
