export interface DomainEntityBase<TIdType extends number | string = number>
  extends DomainEntityId<TIdType> {
  name: string;
}

export interface TranslatableDomainEntityBase<
  TIdType extends number | string = number,
> extends DomainEntityId<TIdType> {
  translatableProperties: TranslatableNameDescriptionModel;
}

export interface DomainEntityId<TType extends number | string = number> {
  id: TType;
}

export type TranslatableNameDescriptionModel = Record<
  string,
  NameDescriptionModel
>;

export interface NameDescriptionModel {
  name: string;
  description: string;
}

export enum DomainEntityType {
  Checklist = 'Checklist',
  Location = 'Location',
  Asset = 'Asset',
  Type = 'Type',
}
