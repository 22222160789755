import { Component, input, output } from '@angular/core';
import { ChecklistTask } from '@fieldos/models';

@Component({
  selector: '',
  template: '',
})
export class ChecklistTaskViewerBaseComponent<
  T extends ChecklistTask = ChecklistTask,
> {
  public readonly value = input<T['defaultValue']>();

  public readonly task = input.required<T>();

  public readonly valueChange = output<unknown>();

  onValueChange(value: T['defaultValue']): void {
    this.valueChange.emit(value);
  }
}
