import { InjectionToken, Type } from '@angular/core';
import { Section, SectionSubType } from '@fieldos/models';
import { SectionsFormAssetSectionComponent } from './sections-form-asset-section/sections-form-asset-section.component';
import { SectionsFormAssigneesSectionComponent } from './sections-form-assignees-section/sections-form-assignees-section.component';
import { SectionsFormChecklistsSectionComponent } from './sections-form-checklists-section/sections-form-checklists-section.component';
import { SectionsFormConfirmLocationSectionComponent } from './sections-form-confirm-location-section/sections-form-confirm-location-section.component';
import { SectionsFormConfirmationSealSectionComponent } from './sections-form-confirmation-seal-section/sections-form-confirmation-seal-section.component';
import { SectionsFormContainerSectionComponent } from './sections-form-container-section/sections-form-container-section.component';
import { SectionsFormCustomerContractSectionComponent } from './sections-form-customer-contract-section/sections-form-customer-contract-section.component';
import { SectionsFormDataSourceSectionComponent } from './sections-form-data-source-section/sections-form-data-source-section.component';
import { SectionsFormDatepickerSectionComponent } from './sections-form-datepicker-section/sections-form-datepicker-section.component';
import { SectionsFormDescriptionSectionComponent } from './sections-form-description-section/sections-form-description-section.component';
import { SectionsFormDpuSectionComponent } from './sections-form-dpu-section/sections-form-dpu-section.component';
import { SectionsFormEstimatedDurationSectionComponent } from './sections-form-estimated-duration-section/sections-form-estimated-duration-section.component';
import { SectionsFormIdSectionComponent } from './sections-form-id-section/sections-form-id-section.component';
import { SectionsFormLocationSectionComponent } from './sections-form-location-section/sections-form-location-section.component';
import { SectionsFormPackagingSectionComponent } from './sections-form-packaging-section/sections-form-packaging-section.component';
import { SectionsFormPrioritySectionComponent } from './sections-form-priority-section/sections-form-priority-section.component';
import { SectionsFormRatingSectionComponent } from './sections-form-rating-section/sections-form-rating-section.component';
import { SectionsFormSectionBaseComponent } from './sections-form-section-base.component';
import { SectionsFormSetSectionComponent } from './sections-form-set-section/sections-form-set-section.component';
import { SectionsFormShowInformationSectionComponent } from './sections-form-show-information-section/sections-form-show-information-section.component';
import { SectionsFormShowTextSectionComponent } from './sections-form-show-text-section/sections-form-show-text-section.component';
import { SectionsFormSignatureSectionComponent } from './sections-form-signature-section/sections-form-signature-section.component';
import { SectionsFormSingleDataSourceSectionComponent } from './sections-form-single-data-source-section/sections-form-single-data-source-section.component';
import { SectionsFormSolvingDescriptionSectionComponent } from './sections-form-solving-description-section/sections-form-solving-description-section.component';
import { SectionsFormStatusSectionComponent } from './sections-form-status-section/sections-form-status-section.component';
import { SectionsFormTitleSectionComponent } from './sections-form-title-section/sections-form-title-section.component';
import { SectionsFormUploadFilesSectionComponent } from './sections-form-upload-files-section/sections-form-upload-files-section.component';

export const SECTION_COMPONENT_MAP = new InjectionToken(
  'SECTION_COMPONENT_MAP',
  {
    factory: (): Record<
      SectionSubType,
      | Type<SectionsFormSectionBaseComponent<Section<any>>>
      | Type<SectionsFormContainerSectionComponent>
    > => ({
      [SectionSubType.Identifier]: SectionsFormIdSectionComponent,
      [SectionSubType.Container]: SectionsFormContainerSectionComponent,
      [SectionSubType.Title]: SectionsFormTitleSectionComponent,
      [SectionSubType.Priority]: SectionsFormPrioritySectionComponent,
      [SectionSubType.SolvingDescription]:
        SectionsFormSolvingDescriptionSectionComponent,
      [SectionSubType.Description]: SectionsFormDescriptionSectionComponent,
      [SectionSubType.Location]: SectionsFormLocationSectionComponent,
      [SectionSubType.Type]: SectionsFormDataSourceSectionComponent,
      [SectionSubType.Customer]: SectionsFormDataSourceSectionComponent,
      [SectionSubType.CustomerContract]:
        SectionsFormCustomerContractSectionComponent,
      [SectionSubType.DueBy]: SectionsFormDatepickerSectionComponent,
      [SectionSubType.StartBy]: SectionsFormDatepickerSectionComponent,

      //TODO:
      [SectionSubType.Assignees]: SectionsFormAssigneesSectionComponent as any,
      [SectionSubType.Teams]: SectionsFormDataSourceSectionComponent,
      [SectionSubType.Media]: SectionsFormUploadFilesSectionComponent,
      [SectionSubType.Documents]: SectionsFormUploadFilesSectionComponent,
      [SectionSubType.Parts]: SectionsFormDataSourceSectionComponent,
      [SectionSubType.Asset]: SectionsFormAssetSectionComponent,
      [SectionSubType.Rating]: SectionsFormRatingSectionComponent,
      [SectionSubType.Signature]: SectionsFormSignatureSectionComponent,
      [SectionSubType.Status]: SectionsFormStatusSectionComponent,
      [SectionSubType.EstimatedDuration]:
        SectionsFormEstimatedDurationSectionComponent,
      [SectionSubType.SingleCustomDataSource]:
        SectionsFormSingleDataSourceSectionComponent,
      [SectionSubType.SingleStandardDataSource]:
        SectionsFormSingleDataSourceSectionComponent,
      [SectionSubType.Supervisors]: SectionsFormDataSourceSectionComponent,
      [SectionSubType.ShowInformation]:
        SectionsFormShowInformationSectionComponent,
      [SectionSubType.ResponsiblePerson]:
        SectionsFormDataSourceSectionComponent,
      [SectionSubType.StartingLocation]: SectionsFormDataSourceSectionComponent,
      [SectionSubType.LoadByDate]: SectionsFormDatepickerSectionComponent,
      [SectionSubType.Set]: SectionsFormSetSectionComponent,
      [SectionSubType.ConfirmLocation]:
        SectionsFormConfirmLocationSectionComponent,
      [SectionSubType.DPU]: SectionsFormDpuSectionComponent,
      [SectionSubType.ShowSealWithConfirmation]:
        SectionsFormConfirmationSealSectionComponent,
      [SectionSubType.Seal]: SectionsFormShowTextSectionComponent,
      [SectionSubType.InputSealWithConfirmation]:
        SectionsFormShowTextSectionComponent,
      [SectionSubType.PackagingLoaded]: SectionsFormPackagingSectionComponent,
      [SectionSubType.PackagingUnloaded]: SectionsFormPackagingSectionComponent,
      [SectionSubType.PackagingLeft]: SectionsFormPackagingSectionComponent,
      [SectionSubType.ConfirmEmtpy]:
        SectionsFormConfirmationSealSectionComponent,
      [SectionSubType.Checklists]: SectionsFormChecklistsSectionComponent,
    }),
  }
);
