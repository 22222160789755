import {
  ChangeDetectorRef,
  Component,
  Input,
  forwardRef,
  inject,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ValueLabelPair } from '@fieldos/models/value-label-pair';
import { AutoUnsubscribe } from '@fieldos/utils';
import { TranslocoModule } from '@ngneat/transloco';
import { Subscription, tap } from 'rxjs';

@Component({
  selector: 'app-slide-toggle-group',
  templateUrl: './slide-toggle-group.component.html',
  standalone: true,
  imports: [ReactiveFormsModule, MatSlideToggleModule, TranslocoModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SlideToggleGroupComponent),
      multi: true,
    },
  ],
})
@AutoUnsubscribe()
export class SlideToggleGroupComponent<T> implements ControlValueAccessor {
  constructor() {
    this._valueChange$ = this.optionsCtrl.valueChanges
      .pipe(
        tap(() => {
          const activeArray = this.optionsCtrl.getRawValue();
          if (activeArray.length !== this._options.length) {
            return;
          }
          const values = activeArray
            .map((isActive, index) =>
              isActive ? this._options[index].value : false
            )
            .filter((e) => e !== null && e !== undefined);
          this._onChange && this._onChange(values as T[]);
        })
      )
      .subscribe();
  }

  @Input() public set frozenOptions(frozenOptions: T[]) {
    this._frozenOptions = frozenOptions;
    this._updateFrozenOptions();
  }

  @Input() public set options(options: ValueLabelPair<T>[]) {
    this.optionsCtrl.clear({ emitEvent: false });

    options.forEach(() =>
      this.optionsCtrl.push(new FormControl(false, { nonNullable: true }))
    );
    this._options = options;
    this._updateFrozenOptions();
  }

  public get options(): ValueLabelPair<T>[] {
    return this._options;
  }

  protected optionsCtrl = new FormArray<FormControl<boolean>>([]);

  private _options: ValueLabelPair<T>[] = [];
  private _frozenOptions: T[] = [];

  private _onChange!: (value: T[]) => void;
  private _onTouch!: () => void;
  private _detector = inject(ChangeDetectorRef);
  private _valueChange$: Subscription;

  writeValue(values: T[]): void {
    setTimeout(() => {
      this.optionsCtrl.setValue(new Array(this._options.length).fill(false), {
        emitEvent: false,
      });

      values.forEach((value) => {
        const index = this._options.findIndex((e) => e.value === value);
        this.optionsCtrl.at(index).setValue(true, { emitEvent: false });
      });
      this._detector.detectChanges();
    });
  }

  registerOnChange(fn: (value: T[]) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    return;
  }

  private _updateFrozenOptions(): void {
    this._options.forEach((option, index) => {
      if (this._frozenOptions.includes(option.value)) {
        if (this.optionsCtrl.at(index).enabled) {
          this.optionsCtrl.at(index).disable({ emitEvent: false });
        }
      } else {
        if (this.optionsCtrl.at(index).disabled) {
          this.optionsCtrl.at(index).enable({ emitEvent: false });
        }
      }
    });
  }
}
