import { TranslatableDomainEntityBase } from '../entity-base';
import { FileModel } from '../file';
import { RightsStatusType } from '../sections';
import { ChecklistTaskType } from './checklist-task-type';

export interface ChecklistTask<
  TChecklistDefaultValue = unknown,
  TConditionalsType = unknown,
> extends TranslatableDomainEntityBase<string> {
  type: ChecklistTaskType;
  mandatory: boolean;
  useConditionals: boolean;
  defaultValue: TChecklistDefaultValue;
  imagesRights: RightsStatusType;
  filesRights: RightsStatusType;
  notesRights: RightsStatusType;
  files: FileModel[];
  link: string;
  notes: string;
  conditionals?: TConditionalsType;
}

export interface ChecklistSection extends TranslatableDomainEntityBase<string> {
  tasks: ChecklistTask[];
  type: 'section';
}

export type ChecklistTaskConditionActionTask =
  | 'upload'
  | 'photo'
  | 'comment'
  | 'custom';
